<template>
  <div class="content">
    <div class="col-6 mx-auto">
      <form class="form-group" @submit.prevent="onSubmit">
        <!-- 아이템 출력 -->
        <div v-for="(entry, index) in state.item" :key="`row_${index}`">
          <!-- input -->
          <div class="row" v-if="entry.inputType == 'input'">
            <label class="col-form-label col-2 flex-wrap">{{
              entry.label
            }}</label>
            <input
              class="form-control col-10"
              type="text"
              v-model="entry.value"
              :placeholder="entry.label"
              :disabled="state.mode == 'ReadShopItem'"
            />
          </div>
          <!-- selectBox -->
          <div class="row" v-if="entry.inputType == 'selectBox'">
            <label class="col-form-label col-2 flex-wrap">{{
              entry.label
            }}</label>
            <select
              class="form-control col-10"
              v-model="entry.value"
              :placeholder="entry.label"
              :disabled="state.mode == 'ReadShopItem'"
            >
              <option
                v-for="code in state.code[entry.id]"
                :key="code.code"
                :value="code.code"
              >
                {{ code.codeName }}
              </option>
            </select>
          </div>
          <!-- file -->
          <div v-if="entry.inputType == 'file'">
            <div
              class="row"
              v-for="(i, index) in entry.value.length + 1"
              :key="index"
              v-show="
                !(state.mode == 'ReadShopItem' && entry.value.length === index)
              "
            >
              <label class="col-form-label col-2 flex-wrap"
                >{{ entry.label }} {{ index + 1 }}</label
              >
              <div class="col-10 row">
                <input
                  type="text"
                  class="form-control col-10"
                  v-if="entry.value.length != index"
                  v-model="entry.value[index].name"
                  disabled
                />
                <input
                  type="text"
                  class="col-10 form-control"
                  v-else
                  disabled
                />

                <input
                  type="file"
                  id="selectFile"
                  accept=".xls*, .ppt*, .doc*, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                  @change="getFile"
                />
                <label
                  for="selectFile"
                  class="col-2 text-center"
                  v-if="entry.value.length == index"
                >
                  파일찾기
                </label>
                <button
                  class="col-2 btn btn-primary image_btn"
                  v-else
                  @click="removeFile(index)"
                  :disabled="state.mode == 'ReadShopItem'"
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 매장정보 조회페이지 -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'ReadShopItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickModifyBtn">
            수정
          </button>
          <button class="btn btn-primary col-6 mx-auto" @click="clickRemoveBtn">
            삭제
          </button>
        </div>
        <!-- 매장정보 등록페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'CreateShopItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickPostBtn">
            저장
          </button>
        </div>
        <!-- 매장정보 수정페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'UpdateShopItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickSaveBtn">
            저장
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, watch, ref, onUpdated } from "vue";
import shopService from "@/services/shop.service";
import codeService from "@/services/code.service";

// # hooks
const router = useRouter();
const routerParams = useRoute().params;

// # state
const state = reactive({
  mode: router.currentRoute.value.name || "",
  code: {
    siDo: {},
    siGunGu: {},
  },
  item: {
    shopName: {
      label: "매장명",
      value: "",
      inputType: "input",
    },
    address: {
      label: "주소",
      value: "",
      inputType: "input",
    },
    addressDetail: {
      label: "상세주소",
      value: "",
      inputType: "input",
    },
    siDo: {
      id: "siDo",
      label: "시/도",
      value: "",
      inputType: "selectBox",
    },
    siGunGu: {
      id: "siGunGu",
      label: "시/군/구",
      value: "",
      inputType: "selectBox",
    },
    openTime: {
      label: "오픈시간",
      value: "",
      inputType: "input",
    },
    closeTime: {
      label: "마감시간",
      value: "",
      inputType: "input",
    },
    shopImages: {
      label: "매장 이미지",
      value: [],
      inputType: "file",
      removeShopImageCodes: [],
    },
  },
  id: routerParams.id || "",
});

// # method
// 단건 조회
const getItem = async () => {
  const data = (await shopService.findOne(state.id)).data;
  for (let key in state.item) {
    state.item[key].value = data[key];
    if (key === "shopImages") {
      state.item[key].value.forEach((i) => {
        i.name = i.fileName;
        state.item.shopImages.removeShopImageCodes.push(i.code);
      });
    }
  }
};

const getSiDoCode = async () => {
  state.code.siDo = (
    await codeService.findAll({ upperCode: "AREA" })
  ).data.content;
};

const getSiGunGuCode = async () => {
  state.code.siGunGu = (
    await codeService.findAll({ upperCode: state.item.siDo.value })
  ).data.content;
};

const getFile = (e) => {
  state.item.shopImages.value.push(e.target.files[0]);
};

const removeFile = (idx) => {
  state.item.shopImages.value.splice(idx, 1);
};

// # Btn Click Event
const clickPostBtn = async () => {
  const params = new FormData();
  for (var key in state.item) {
    if (key === "shopImages") {
      state.item[key].value.forEach((value) => params.append(key, value));
    } else {
      params.append(key, state.item[key].value);
    }
  }
  await shopService.create(params);
  router.push({
    name: "ReadShopList",
  });
};

const clickModifyBtn = () => {
  router.push({
    name: "UpdateShopItem",
    params: { id: state.id },
  });
  state.mode = "UpdateShopItem";
};

const clickSaveBtn = async () => {
  const params = new FormData();
  for (var key in state.item) {
    if (key === "shopImages") {
      state.item[key].value.forEach((value) => {
        if (value instanceof File) {
          params.append(key, value);
        } else {
          state.item.shopImages.removeShopImageCodes.splice(
            state.item.shopImages.removeShopImageCodes.indexOf(value.code),
            1
          );
        }
      });
      params.append("shopImageCodes", state.item[key].removeShopImageCodes);
    } else {
      params.append(key, state.item[key].value);
    }
  }
  await shopService.update(state.id, params);
  router.push({
    name: "ReadShopItem",
    params: { id: state.id },
  });
  state.mode = "ReadShopItem";
};

const clickRemoveBtn = async () => {
  await shopService.delete(state.id);
  router.push({
    name: "ReadShopList",
  });
};

// # life cycle
onMounted(() => {
  getSiDoCode();
  if (state.mode != "CreateShopItem") {
    getItem();
  }
});

// 시/도 데이터가 변할 때마다 시/군/구 코드 불러옴
watch(
  () => state.item.siDo.value,
  () => {
    getSiGunGuCode();
  }
);
</script>

<style lang="scss" scoped>
.image_btn {
  vertical-align: top;
  margin: 0;
}

.row {
  height: 32px;
}
</style>
